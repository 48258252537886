import React, { useState, useEffect } from 'react';

import { ScrollArea } from "@/components/ui/scroll-area";

import { HandPlatter, Building, Trash2, CalendarClock } from "lucide-react";

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import { motion } from "framer-motion";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';

import TopBar from '../../core/widgets/ui/TopBar';
import ComponentTimer from 'core/widgets/util/ComponentTimer';

import ServiceFloor from '../../services/serviceFloor';

import isRoom from '../../assets/image/isRoom.png';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';
import animPage from '../../assets/anim/anim-pageload.json';

export default function PanelSettingsRoom(props) {

    const [token] = useRecoilState(tokenAtom);

    const [floors, setFloors] = useState([]);
    const [filterFloor, setFilterFloor] = useState([]);
    const [types, setTypes] = useState([]);

    const [aflor, setAFloor] = useState({});

    const [aid, setAID] = useState('');
    const [selFloor, setSelFloor] = useState('all');

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenB, setIsOpenB] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(true);

    const floorService = new ServiceFloor();

    const formVSchema = Yup.object().shape({
        title: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            title: '',
            rtype: '',
        },
        validationSchema: formVSchema,
        enableReinitialize: true,
        onSubmit: values => {
            setSubmit(true);

            if (isOpen) {
                let body = {
                    "title": values.title,
                    "index": floors.length,
                    "units": [],
                    "status": 'active'
                };
                floorService.setFloor(body, token).then((res) => {
                    setSubmit(false);
                    if (res.status) {
                        resetForm();
                        setIsOpen(false);
                        loadData();
                        toast.success("New Floor Added", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    } else {
                        toast.error("Server error, please try again!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                });
            }

            if (isOpenB) {
                if (values.rtype.length === 0) {
                    toast.error("Please select a room type!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                } else {
                    let newUnit = {
                        "title": values.title,
                        "type": "Room",
                        "rtype": getRoomType(values.rtype),
                        "total": 0.00,
                        "paid": 0.00,
                        "pending": 0.00,
                        "status": "none",
                        "startTime": new Date().toISOString(),
                        "endTime": new Date().toISOString(),
                    }
                    let units = [...aflor.units];
                    units.push(newUnit);
                    let body = {
                        fid: aid,
                        data: {
                            units: units
                        }
                    };
                    floorService.updateFloorById(body, token).then((res) => {
                        setSubmit(false);
                        if (res.status) {
                            resetForm();
                            setIsOpenB(false);
                            loadData();
                            toast.success("Floor updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        } else {
                            toast.error("Server error, please try again!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                    });
                }
            }
        }
    });

    const getRoomType = (id) => {
        return types.filter((item) => item._id === id)[0];
    }

    const openAddFloor = (id, floor) => {
        setIsOpenB(true);
        setAID(id);
        setAFloor(floor);
    }

    const deleteFloor = (floorData) => {
        let isDelete = true;

        floorData.units.forEach(unit => {
            if (unit.status !== 'none') {
                isDelete = false;
            }
        });

        if (isDelete) {
            let body = {
                fid: floorData._id,
            }
            floorService.deleteFloorById(body, token).then((res) => {
                if (res['status']) {
                    loadData();
                } else {
                    toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            });
        } else {
            toast.error('Please clear all the bills for the floor.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        }
    }

    const deleteTable = (floorData, index) => {
        if (floorData.units[index].status === 'none') {
            let units = [...floorData.units];
            units.splice(index, 1);
            let body = {
                fid: floorData._id,
                data: {
                    units: units
                }
            };
            floorService.updateFloorById(body, token).then((res) => {
                setSubmit(false);
                if (res.status) {
                    loadData();
                    toast.success("Floor updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                } else {
                    toast.error("Server error, please try again!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            });
        } else {
            toast.error('Please clear all the bills for the floor.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        }
    }

    const sortTable = (filter) => {
        setSelFloor(filter);

        if (filter === 'all') {
            setFilterFloor(floors);
        } else {
            let tempList = floors.filter((item) => item.title === filter);
            setFilterFloor(tempList);
        }
    }

    const getUnits = (units) => {
        return units.filter((item) => item.type === 'Room');
    }

    const getColor = (sts) => {
        switch (sts) {
            case 'active':
                return 'bg-green-50';
            case 'overdeu':
                return 'bg-red-50';
            case 'inprog':
                return 'bg-yellow-50';
            case 'none':
            default:
                return 'bg-white';

        }
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            var docType = await floorService.getAllRoomType({}, token);
            setTypes(docType.data);
            var doc = await floorService.getAllFloor({}, token);
            setFloors(doc.data);
            setFilterFloor(doc.data);
            setSelFloor('all');
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            {
                isOpen && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                        <div className='flex items-center justify-between'>
                            <h3 className="text-xl font-semibold text-gray-900">
                                Add Floor &nbsp;
                            </h3>
                            <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                setIsOpen(false);
                                resetForm();
                            }}>
                                <i className="las la-times text-sm text-black"></i>
                            </span>
                        </div>
                        <hr className='mt-2' />
                        <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">Floor Name<sup className="text-red-600">*</sup></label>
                                <input type="text" id="title" value={values.title} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange} />
                                {(errors.title && touched.title) && <p className='text-xs text-red-400 mt-1'>{errors.title}</p>}
                            </div>
                            <div className='flex items-end justify-end'>
                                <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                    {!submit && "ADD"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            }
            {
                isOpenB && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                        <div className='flex items-center justify-between'>
                            <h3 className="text-xl font-semibold text-gray-900">
                                Add Room &nbsp;
                            </h3>
                            <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                setIsOpenB(false);
                                resetForm();
                            }}>
                                <i className="las la-times text-sm text-black"></i>
                            </span>
                        </div>
                        <hr className='mt-2' />
                        <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">Room Name<sup className="text-red-600">*</sup></label>
                                <input type="text" id="title" value={values.title} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange} />
                                {(errors.title && touched.title) && <p className='text-xs text-red-400 mt-1'>{errors.title}</p>}
                            </div>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">Select Room Type<sup className="text-red-600">*</sup></label>
                                <select type="text" id="rtype" value={values.rtype} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange}>
                                    <option value="">Select room type</option>
                                    {
                                        types.map((type) => {
                                            return <option value={type._id}>{type.title}</option>
                                        })
                                    }
                                </select>
                                {(errors.rtype && touched.rtype) && <p className='text-xs text-red-400 mt-1'>{errors.rtype}</p>}
                            </div>
                            <div className='flex items-end justify-end'>
                                <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                    {!submit && "ADD"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            }
            <ScrollArea className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                            <h2 class="text-2xl font-medium truncate ml-2">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Rooms Settings
                            </h2>
                            <TopBar />
                        </div>
                        <div class="w-full mt-2">
                            <div className="p-2 mt-2">
                                <div className='w-full min-h-screen bg-gray-100 rounded-md grid grid-cols-12 gap-2 p-2'>
                                    <div className='col-span-2 h-full border-r p-2'>
                                        <button className="w-full h-10 py-1 px-3 rounded-md bg-prime hover:bg-primeLight text-white text-sm" onClick={() => { setIsOpen(true); }}><i class="las la-plus mr-1"></i> ADD FLOOR</button>
                                        <ul>
                                            <li className={`py-2 uppercase text-sm font-medium my-2 cursor-pointer rounded-md px-2 border border-dashed ${selFloor === 'all' ? 'text-prime border-prime bg-red-100' : 'hover:bg-gray-200'}`} onClick={() => {
                                                sortTable('all');
                                            }}>
                                                All Floors
                                            </li>
                                            {
                                                floors.length !== 0 && floors.map((cunit) => {
                                                    return <li className={`py-2 uppercase text-sm font-medium my-2 cursor-pointer rounded-md px-2 border border-dashed ${selFloor === cunit.title ? 'text-prime border-prime bg-red-100' : 'hover:bg-gray-200'}`} key={cunit._id}>
                                                        <div className='flex items-center justify-between'>
                                                            <span className='flex-grow' onClick={() => { sortTable(cunit.title); }}>{cunit.title}</span>
                                                            <div className='cursor-pointer' onClick={() => { deleteFloor(cunit); }}>
                                                                <Trash2 size={18} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className='col-span-10'>
                                        {
                                            filterFloor.length !== 0 && filterFloor.map((cuint) => {
                                                return <div className='w-full my-4 border border-gray-200 border-dashed p-1 rounded'>
                                                    <div className='w-full'>
                                                        <div className='flex items-center justify-between'>
                                                            <div className='flex items-center'>
                                                                <Building size={22} />
                                                                <h1 className='text-xl font-medium uppercase ml-1'>{cuint.title}</h1>
                                                            </div>
                                                            <div className='w-[28px] h-[28px] bg-prime flex items-center justify-center text-2xl font-medium rounded text-white cursor-pointer hover:bg-primeLight' onClick={() => { openAddFloor(cuint._id, cuint); }}>+</div>
                                                        </div>
                                                        <div className='my-2 h-[1px] bg-gray-300'></div>
                                                    </div>
                                                    <div className='w-full grid grid-cols-6 gap-2'>
                                                        {
                                                            getUnits(cuint.units).length === 0 && < div className='h-[114px] p-2 rounded shadow col-span-6 border border-dashed border-prime cursor-pointer hover:shadow-md flex items-center justify-center'>
                                                                <h1 className='text-sm font-medium text-prime'>No data found.</h1>
                                                            </div>
                                                        }
                                                        {
                                                            getUnits(cuint.units).length !== 0 && getUnits(cuint.units).map((unit, index) => {
                                                                return < div className={`h-[114px] p-2 rounded shadow col-span-1 border border-dashed border-gray-400 cursor-pointer hover:shadow-md ${getColor(unit.status)}`} key={index}>
                                                                    <div className='flex justify-between items-center px-1'>
                                                                        <div>
                                                                            <p className='text-xl font-medium font-serif'>{unit.title}</p>
                                                                            <div className='flex items-center'>
                                                                                <CalendarClock size={18} />
                                                                                <ComponentTimer startTime={unit.endTime} isOn={unit.status !== 'none'} isElapsed={true} />
                                                                            </div>
                                                                        </div>
                                                                        <img src={isRoom} alt='Table icon' className='w-[54px] h-[54px]' />
                                                                    </div>
                                                                    <div className='bg-gray-200 h-[1px] my-2'></div>
                                                                    <div className='flex items-center justify-between'>
                                                                        <div className='text-gray-400 flex items-center'>
                                                                            <HandPlatter size={20} />
                                                                            <p className='text-base font-medium font-serif ml-1'>₹ {unit.pending.toFixed(2)}</p>
                                                                        </div>
                                                                        <Trash2 size={18} className='cursor-pointer' onClick={() => {
                                                                            deleteTable(cuint, index);
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollArea>
        </>
    )
}
