import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceFloor {
    lookupService = new APIServiceLookUp();

    async getAllFloor(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}floor/getAllFloor`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getFloorById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}floor/getFloorById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateFloorById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}floor/updateFloor`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deleteFloorById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}floor/deleteFloorById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setFloor(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}floor/setFloor`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async addRoomType(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}room/addType`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deleteRoomType(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}room/delType`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllRoomType(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}room/getAll`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}