import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom, paramsAtom } from '../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import Moment from 'moment';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceFloor from '../../services/serviceFloor';
import ServiceBooking from '../../services/serviceBooking';

export default function PanelPosCheckIn() {

    const [token] = useRecoilState(tokenAtom);
    const [param, setParam] = useRecoilState(paramsAtom);

    const [types, setTypes] = useState([]);
    const [floors, setFloors] = useState([]);
    const [rooms, setRooms] = useState([]);

    const [book, setBook] = useState({});

    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const floorService = new ServiceFloor();
    const bookService = new ServiceBooking();

    const formVSchema = Yup.object().shape({
        total: Yup.string().required('This information is required'),
        tax: Yup.string().required('This information is required'),
        discount: Yup.string().required('This information is required'),
        advance: Yup.string().required('This information is required'),
        paid: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
        initialValues: {
            total: 0,
            tax: 0,
            discount: 0,
            advance: 0,
            paid: 0,
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            var body = {
                "bid": param.bid,
                "data": {
                    "rooms": rooms,
                    "paid": (book.finaltotal - (values.paid + book.paid)),
                    "status": "prog",
                }
            };
            bookService.updateBooking(body, token).then((res) => {
                if (res.status) {
                    toast.success('Room booking updated.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    gotoBook();
                } else {
                    toast.error('Server error please try again later.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            });
        }
    });

    const gotoBook = () => {
        setParam({});
        navigate(`/home/pos`);
    }

    const isRoomSelected = (title) => {
        return rooms.find((a) => a.title === title);
    }

    const toggleRoom = (room) => {
        let roomIndex = rooms.findIndex((a) => a.title === room.title);
        let tempList = [...rooms];
        if (roomIndex === -1) {
            tempList.push(room);
        } else {
            tempList.splice(roomIndex, 1);
        }
        setRooms(tempList);
    }

    const getBooking = () => {
        (async function () {
            if (param.bid !== undefined) {
                var docBook = await bookService.getBookById({ 'id': param.bid }, token);
                const tempBook = docBook.data;
                setBook(docBook.data);
                setValues({
                    total: tempBook.total,
                    tax: tempBook.tax,
                    discount: tempBook.discount,
                    advance: tempBook.paid,
                    paid: (tempBook.finaltotal - tempBook.paid),
                });
                var tempList = [...types];
                tempBook.inventory.map((item) => {
                    const index = types.findIndex((a) => a._id === item._id);
                    if (index !== -1) {
                        tempList[index].qty = item.qty;
                    }
                });
            }
        })();
    }

    const loadData = () => {
        (async function () {
            var docType = await floorService.getAllRoomType({}, token);
            let tempType = [];
            docType.data.map((item) => {
                tempType.push({ ...item, qty: 0 });
            });
            setTypes(tempType);
            var doc = await floorService.getAllFloor({}, token);
            setFloors(doc.data);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (types.length !== 0) {
            if (Object.keys(param).length !== 0) {
                getBooking();
            }
        }
    }, [types, param]);

    return (
        <div className="w-full h-full p-2 flex flex-col overflow-auto">
            <div className="p-3">
                <form onSubmit={handleSubmit}>
                    <div className="intro-y flex items-center cursor-pointer" onClick={() => { gotoBook() }}>
                        <i className="las la-long-arrow-alt-left text-xl"></i>
                        <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all bookings</h4>
                    </div>
                    <div className="intro-y mt-4">
                        <h3 className="text-2xl font-sans font-bold text-gray-800">Check-in Booking</h3>
                        <p className="mt-1 text-sm text-gray-600 mb-6">
                            Please enter corret details and information related to booking.
                        </p>
                        <hr />
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg flex items-start">
                                        <div className="flex items-center space-x-4 mr-4">
                                            <div className='min-w-[100px] flex flex-col items-center border bg-gray-50 border-prime p-2 rounded-md space-y-4'>
                                                <p className='text-sm font-medium text-gray-600'>FROM</p>
                                                <p className='text-4xl font-medium'>{Moment(book.sdate).format('DD')}</p>
                                                <p className='text-sm font-medium text-gray-600 uppercase'>{Moment(book.sdate).format('ddd')}</p>
                                            </div>
                                            <div>-</div>
                                            <div className='min-w-[100px] flex flex-col items-center border bg-gray-50 border-prime p-2 rounded-md space-y-4'>
                                                <p className='text-sm font-medium text-gray-600'>TO</p>
                                                <p className='text-4xl font-medium'>{Moment(book.edate).format('DD')}</p>
                                                <p className='text-sm font-medium text-gray-600 uppercase'>{Moment(book.edate).format('ddd')}</p>
                                            </div>
                                        </div>
                                        <div className="space-y-1 flex flex-col items-start">
                                            <p className=''>Booking ID:&nbsp;<span className='font-medium'>{book.bookid}</span></p>
                                            <p className='text-sm'>Name:&nbsp;<span className='font-medium'>{book.bookName}</span></p>
                                            <div className='flex space-x-2'>
                                                <p className='text-sm text-gray-600 font-medium'>Email:&nbsp;<span>{book.bookEmail}</span></p>
                                                <p className='text-sm text-gray-600 font-medium'>Phone:&nbsp;<span>{book.bookPhone}</span></p>
                                            </div>
                                            <div className='flex-grow'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="col-span-12">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Room Details</h3>
                                            </div>
                                            <div className="col-span-12">
                                                {
                                                    floors.length !== 0 && floors.map((cuint) => {
                                                        return <div className='w-full grid grid-cols-12 gap-2 my-2'>
                                                            {
                                                                cuint.units.length !== 0 && cuint.units.map((unit, index) => {
                                                                    if (unit.type === 'Room' && unit.type !== 'active') {
                                                                        return < div className={`p-2 rounded col-span-1 border border-dashed border-gray-400 cursor-pointer hover:shadow-md ${isRoomSelected(unit.title) ? "bg-green-600" : "bg-white"} `} key={index} onClick={() => { toggleRoom(unit); }}>
                                                                            <div className='flex justify-between items-center px-1'>
                                                                                <div>
                                                                                    <p className='text-xl font-medium font-serif'>{unit.title}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="col-span-12">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Customer Details</h3>
                                            </div>
                                            <div className="col-span-12 gap-2">
                                                {
                                                    Array.from({ length: book.nop }).map((_, index) => (
                                                        <div key={index} className='w-full grid grid-cols-12 gap-2 m-4'>
                                                            <div className="col-span-12">
                                                                <h3 className="text-sm font-medium leading-6 text-gray-900">Customer {index + 1} Details </h3>
                                                            </div>
                                                            <div className="col-span-3">
                                                                <label className="block text-xs font-medium text-gray-700 font-mono">Customer Name</label>
                                                                <input type="text" id="custName" autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" />
                                                            </div>
                                                            <div className="col-span-3">
                                                                <label className="block text-xs font-medium text-gray-700 font-mono">Customer Number Document</label>
                                                                <input type="text" id="custName" autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" />
                                                            </div>
                                                            <div className="col-span-3">
                                                                <label className="block text-xs font-medium text-gray-700 font-mono">Customer Document</label>
                                                                <input type="file" id="custDoc" autoComplete="off" className="mt-1 p-1 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" />
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="col-span-12">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Booking Payments</h3>
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Total as per system<sup className="text-red-600">*</sup></label>
                                                <input type="number" id="total" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" value={values.total} />
                                                {(errors.total && touched.total) && <p className='text-xs text-red-400 mt-1'>{errors.total}</p>}
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Total tax<sup className="text-red-600">*</sup></label>
                                                <input type="number" id="tax" onChange={handleChange} autoComplete="off" readOnly={true} className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" value={values.tax} />
                                                {(errors.tax && touched.tax) && <p className='text-xs text-red-400 mt-1'>{errors.tax}</p>}
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Total Discount<sup className="text-red-600">*</sup></label>
                                                <input type="number" id="discount" onChange={handleChange} autoComplete="off" readOnly={true} className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" value={values.discount} />
                                                {(errors.discount && touched.discount) && <p className='text-xs text-red-400 mt-1'>{errors.discount}</p>}
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Net Total<sup className="text-red-600">*</sup></label>
                                                <input type="number" id="finaltotal" autoComplete="off" readOnly={true} className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" value={(values.total + values.tax) - values.discount} />
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Advance Paid<sup className="text-red-600">*</sup></label>
                                                <input type="number" id="advance" onChange={handleChange} autoComplete="off" readOnly={true} className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" value={values.advance} />
                                                {(errors.advance && touched.advance) && <p className='text-xs text-red-400 mt-1'>{errors.advance}</p>}
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Pending Payment<sup className="text-red-600">*</sup></label>
                                                <input type="number" id="paid" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" value={values.paid} />
                                                {(errors.paid && touched.paid) && <p className='text-xs text-red-400 mt-1'>{errors.paid}</p>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                        <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "CHECK IN"}
                        </button>
                    </div>
                </form >
            </div >
        </div >
    )
}
