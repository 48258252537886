import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom, userAtom } from '../../core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import { motion } from "framer-motion";

import TopBar from '../../core/widgets/ui/TopBar';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceRawwItem from '../../services/serviceRawwItem';
import ServiceInventory from '../../services/serviceInventory';

export default function PanelInventoryAdjAdd(props) {

    const adjTypes = [
        { value: 'Opening', label: 'Opening' },
        { value: 'Adjustment', label: 'Adjustment' },
        { value: 'Purchase', label: 'Purchase' },
        { value: 'Transfer', label: 'Transfer' },
        { value: 'Received', label: 'Received' },
    ];

    const [token] = useRecoilState(tokenAtom);
    const [userData] = useRecoilState(userAtom);

    const [items, setItems] = useState([]);
    const [adjItems, setAdjItem] = useState([]);

    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const rawwItemService = new ServiceRawwItem();
    const inventoryService = new ServiceInventory();

    const formVSchema = Yup.object().shape({
        type: Yup.string().required('This information is required'),
        note: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
        initialValues: {
            type: '',
            note: '',
            extra: ''
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                "id": generateID(),
                "type": values.type,
                "note": values.note,
                "extra": values.extra,
                "uid": userData._id,
                "items": adjItems,
            }
            inventoryService.setInventory(body, token).then((res) => {
                setSubmit(false);
                if (res.status) {
                    toast.success("Raww Item Updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    goBack();
                } else {
                    toast.error('Server error please try again..!!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            });
        }
    });

    const generateID = () => {
        const timestamp = Date.now().toString();
        const randomNum = Math.floor(Math.random() * 10000).toString();
        const uniqueID = timestamp.slice(-6) + randomNum;
        return "ADJ" + uniqueID.slice(0, 10);
    };

    const getItemValue = (id) => {
        return adjItems.find((a) => a.id === id).values
    }

    const handleChangeForm = (e) => {
        const { value, name } = e.target;
        const updatedItems = adjItems.map(item =>
            item.id === name ? { ...item, values: value } : item
        );
        setAdjItem(updatedItems);
    }

    const goBack = () => {
        props.onOpen();
        navigate(`/home/inventoryList`);
    }

    const loadData = () => {
        (async function () {
            let body = {};
            var res = await rawwItemService.getAllRawwItems(body, token);
            if (res['status']) {
                setItems(res.data);
                let TempVals = [];
                res.data.map((item) => {
                    TempVals.push({ 'id': item._id, name: item.itemName, unit: item.itemUnit, values: item.stock, oldVal: item.stock });
                });
                setAdjItem(TempVals);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <ScrollArea className="w-full h-full py-3 px-3">
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                        <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                            <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Add Inventory Adjustment
                        </h2>
                        <TopBar />
                    </div>
                </div>
            </div>
            <div className="w-full h-full p-2 flex flex-col overflow-auto">
                <div className="p-3">
                    <form onSubmit={handleSubmit}>
                        <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                            <i className="las la-long-arrow-alt-left text-xl"></i>
                            <h4 className="text-xs text-black font-sans font-medium ml-2">Back to inventory</h4>
                        </div>
                        <div className="intro-y mt-4">
                            <h3 className="text-2xl font-sans font-bold text-gray-800">Add Inventory Addjustment</h3>
                            <p className="mt-1 text-sm text-gray-600 mb-6">
                                Please enter corret details and information related to inventory addjustment.
                            </p>
                            <hr />
                        </div>
                        <div className="mt-4">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                    <div className="shadow">
                                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6">
                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Inventory Adjustment Information</h3>
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Adjustment Type<sup className="text-red-600">*</sup></label>
                                                    <select id="type" className="appearance-none mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" onChange={handleChange} value={values.type}>
                                                        <option value="" disabled="" selected="selected">Select</option>
                                                        {
                                                            adjTypes.length !== 0 && adjTypes.map((item) => {
                                                                return <option value={item.value} selected={values.uType == item.value}>{item.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {(errors.type && touched.type) && <p className='text-xs text-red-400 mt-1'>{errors.type}</p>}
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Adjustment Note.<sup className="text-red-600">*</sup></label>
                                                    <input type="text" id="note" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Adjustment Note" value={values.note} />
                                                    {(errors.note && touched.note) && <p className='text-xs text-red-400 mt-1'>{errors.note}</p>}
                                                </div>

                                                <div className="col-span-6 sm:col-span-2"></div>
                                                {
                                                    values.type === "Purchase" && <div className="col-span-6 sm:col-span-2">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Purchase Bill No.</label>
                                                        <input type="text" id="extra" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Bill No." value={values.extra} />
                                                        {(errors.extra && touched.extra) && <p className='text-xs text-red-400 mt-1'>{errors.extra}</p>}
                                                    </div>
                                                }
                                                {
                                                    values.type === "Transfer" && <div className="col-span-6 sm:col-span-2">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Transfer To.</label>
                                                        <input type="text" id="extra" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Destination Code" value={values.extra} />
                                                        {(errors.extra && touched.extra) && <p className='text-xs text-red-400 mt-1'>{errors.extra}</p>}
                                                    </div>
                                                }
                                                {
                                                    values.type === "Received" && <div className="col-span-6 sm:col-span-2">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Received From.</label>
                                                        <input type="text" id="extra" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Orgin Code" value={values.extra} />
                                                        {(errors.extra && touched.extra) && <p className='text-xs text-red-400 mt-1'>{errors.extra}</p>}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                    <div className="shadow">
                                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6">
                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Adjustment Items</h3>
                                                </div>

                                                <div className="col-span-12">
                                                    <table className="min-w-full divide-y divide-gray-200 px-4">
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    ITEM ID
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    ITEM Name
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    ITEM CATERGORY
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    CURRENT STOCK
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Adjustment
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                            {
                                                                items.length !== 0 && items.map((item) => {
                                                                    return <tr className="hover:bg-gray-100" key={item._id}>
                                                                        <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                                                            <div className="text-sm font-bold text-gray-900">
                                                                                <div className='flex items-center text-prime'>
                                                                                    {item.itemId}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="text-sm font-bold text-gray-900 uppercase">
                                                                                {item.itemName}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="text-sm font-bold text-gray-900 uppercase">
                                                                                {item.itemCategory}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="text-sm font-bold text-gray-900">
                                                                                {item.stock} {item.itemUnit}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="text-sm font-bold text-gray-900">
                                                                                <input type="number" name={item._id} onChange={handleChangeForm} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-white text-sm border-gray-400 rounded outline-none border" placeholder="i.e. 1" value={getItemValue(item._id)} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                            <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && "ADD ADJUSTMENT"}
                            </button>
                        </div>
                    </form >
                </div >
            </div>
        </ScrollArea>
    )
}
