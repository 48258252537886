import React from 'react';
import { useParams } from 'react-router-dom';

import PanelPosMain from '../panels/pos/PanelPosMain';
import PanelPosOrder from 'panels/pos/PanelPosOrder';
import PanelPosBook from 'panels/pos/PanelPosBook';
import PanelPosCheckIn from 'panels/pos/PanelPosCheckIn';
import PanelPosCheckOut from 'panels/pos/PanelPosCheckOut';

import PanelDev from '../panels/dev/PanelDev';

export default function LayoutPos() {

    const { page } = useParams();

    return (
        <div className='h-screen w-full'>
            {
                (() => {
                    switch (page) {
                        case 'pos':
                            return (<PanelPosMain />);
                        case 'posOrder':
                            return (<PanelPosOrder />);
                        case 'posBook':
                            return (<PanelPosBook />);
                        case 'posCheckin':
                            return (<PanelPosCheckIn />);
                        case 'posCheckOut':
                            return (<PanelPosCheckOut />);
                        default:
                            return (<PanelDev />);
                    }
                })()
            }
        </div>
    )
}
