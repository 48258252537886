import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AnimatePresence, motion } from "framer-motion";

import { FileText, FilePieChart, Bed } from 'lucide-react';

import PanelDev from '../panels/dev/PanelDev';

import PanelOrder from 'panels/order/PanelOrder';
import PanelInvoice from 'panels/order/PanelInvoice';
import PanelBooking from 'panels/order/PanelBooking';

export default function LayoutOrder() {

    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();

    const navigate = useNavigate();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => {
        setIsOpen(false);
    }

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-2xl font-medium px-2 mx-2 py-1 my-2 rounded">Orders</motion.h1>
                        <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="w-64 flex flex-col items-start">
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'order' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('order') }}>
                                <FilePieChart size={20} className='mr-2' /> Orders Tally
                            </li>
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'invoices' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('invoices') }}>
                                <FileText size={20} className='mr-2' /> Invoice Tally
                            </li>
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'booking' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('booking') }}>
                                <Bed size={20} className='mr-2' /> Booking Tally
                            </li>
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'order':
                                return (<PanelOrder onToggle={toggleMenu} />);
                            case 'invoices':
                                return (<PanelInvoice onToggle={toggleMenu} />);
                            case 'booking':
                                return (<PanelBooking onToggle={toggleMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
