import React, { useState, useEffect } from 'react';

import { ScrollArea } from "@/components/ui/scroll-area";

import { useRecoilState } from 'recoil';
import { tokenAtom } from 'core/config/atoms';

import { motion } from "framer-motion";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';

import TopBar from 'core/widgets/ui/TopBar';

import ServiceFloor from 'services/serviceFloor';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';
import animPage from '../../assets/anim/anim-pageload.json';
import { Building, CalendarClock, HandPlatter, Trash2 } from 'lucide-react';

import isRoom from '../../assets/image/isRoom.png';

export default function PanelSettingsRoomType(props) {

    const [token] = useRecoilState(tokenAtom);

    const [types, setTypes] = useState([]);

    const [selRoomType, setSelRoomType] = useState({});

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenB, setIsOpenB] = useState(false);
    const [submit, setSubmit] = useState(false);

    const floorService = new ServiceFloor();

    const formVSchema = Yup.object().shape({
        title: Yup.string().required('This information is required'),
        nop: Yup.string().required('This information is required'),
        plus: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
        initialValues: {
            title: '',
            nop: '',
            plus: '',
            ratesa: 0,
            ratesb: 0,
            ratesc: 0,
            ratesd: 0,
            ratese: 0,
            ratesf: 0,
            ratesg: 0
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                title: values.title,
                nop: values.nop,
                plus: values.plus,
                ratea: values.ratesa,
                rateb: values.ratesb,
                ratec: values.ratesc,
                rated: values.ratesd,
                ratee: values.ratese,
                ratef: values.ratesf,
                rateg: values.ratesg
            }
            floorService.addRoomType(body, token).then((res) => {
                setSubmit(false);
                if (res.status) {
                    toast.success("New room type added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    setIsOpen(false);
                    handleReset();
                    loadData();
                } else {
                    toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            });
        }
    });

    const deleteRoom = (unit) => {
        let body = {
            rid: unit._id,
        }
        floorService.deleteRoomType(body, token).then((res) => {
            if (res.status) {
                toast.success("Room Type Deleted!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                handleReset();
                loadData();
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        });
    }

    const loadData = () => {
        (async function () {
            var doc = await floorService.getAllRoomType({}, token);
            setTypes(doc.data);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            {
                isOpen && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] min-h-screen absolute right-0 p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900 uppercase'>Add Room Type</h1>
                                    <span onClick={() => {
                                        setIsOpen(false);
                                        handleReset();
                                        loadData();
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <hr className='mt-2' />
                                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                                    <div className="mb-4">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Room Type Title<sup className="text-red-600">*</sup></label>
                                        <input type="text" id="title" value={values.title} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange} />
                                        {(errors.title && touched.title) && <p className='text-xs text-red-400 mt-1'>{errors.title}</p>}
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">No of pepole<sup className="text-red-600">*</sup></label>
                                        <input type="text" id="nop" value={values.nop} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange} />
                                        {(errors.nop && touched.nop) && <p className='text-xs text-red-400 mt-1'>{errors.nop}</p>}
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Plus pepole<sup className="text-red-600">*</sup></label>
                                        <input type="text" id="plus" value={values.plus} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange} />
                                        {(errors.plus && touched.plus) && <p className='text-xs text-red-400 mt-1'>{errors.plus}</p>}
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Rate as per Monday</label>
                                        <input type="text" id="ratesa" value={values.ratesa} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange} />
                                        {(errors.ratesa && touched.ratesa) && <p className='text-xs text-red-400 mt-1'>{errors.ratesa}</p>}
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Rate as per Tuesday</label>
                                        <input type="text" id="ratesb" value={values.ratesb} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange} />
                                        {(errors.ratesb && touched.ratesb) && <p className='text-xs text-red-400 mt-1'>{errors.ratesb}</p>}
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Rate as per Wednesday</label>
                                        <input type="text" id="ratesc" value={values.ratesc} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange} />
                                        {(errors.ratesc && touched.ratesc) && <p className='text-xs text-red-400 mt-1'>{errors.ratesc}</p>}
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Rate as per Thursday</label>
                                        <input type="text" id="ratesd" value={values.ratesd} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange} />
                                        {(errors.ratesd && touched.ratesd) && <p className='text-xs text-red-400 mt-1'>{errors.ratesd}</p>}
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Rate as per Friday</label>
                                        <input type="text" id="ratese" value={values.ratese} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange} />
                                        {(errors.ratese && touched.ratese) && <p className='text-xs text-red-400 mt-1'>{errors.ratese}</p>}
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Rate as per Saturday</label>
                                        <input type="text" id="ratesf" value={values.ratesf} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange} />
                                        {(errors.ratesf && touched.ratesf) && <p className='text-xs text-red-400 mt-1'>{errors.ratesf}</p>}
                                    </div>
                                    <div className="mb-12">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Rate as per Sunday</label>
                                        <input type="text" id="ratesg" value={values.ratesg} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange} />
                                        {(errors.ratesg && touched.ratesg) && <p className='text-xs text-red-400 mt-1'>{errors.ratesg}</p>}
                                    </div>
                                    <div className='flex items-end justify-end'>
                                        <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                            {!submit && "ADD"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </ScrollArea >
                    </div >
                </div >
            }
            {
                isOpenB && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] min-h-screen absolute right-0 p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900 uppercase'>Room Type</h1>
                                    <span onClick={() => {
                                        setIsOpenB(false);
                                        setSelRoomType({});
                                        loadData();
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <hr className='my-2' />
                                <h1 className='text-xl font-sans font-medium text-gray-900 uppercase'>{selRoomType.title}</h1>
                                <div className='text-gray-700 flex items-center space-x-4 my-2'>
                                    <h1 className='font-sans font-medium'>Number of pepole: <span className='text-gray-900'>{selRoomType.nop}</span></h1>
                                    <h1 className='font-sans font-medium'>Number of extra: <span className='text-gray-900'>{selRoomType.plus}</span></h1>
                                </div>
                                <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                    <span className='flex items-center'>
                                        <i className="las la-rupee-sign mr-2 text-xl"></i>Room Rates
                                    </span>
                                </div>
                                <ul className='text-gray-800 my-2 space-y-1 w-1/2'>
                                    <li className='flex items-center justify-between'>
                                        <h1 className='font-sans font-medium'>Monday:</h1>
                                        <div><span className='text-green-600'>₹ {selRoomType.ratea}</span></div>
                                    </li>
                                    <li className='flex items-center justify-between'>
                                        <h1 className='font-sans font-medium'>Tuesday:</h1>
                                        <div><span className='text-green-600'>₹ {selRoomType.rateb}</span></div>
                                    </li>
                                    <li className='flex items-center justify-between'>
                                        <h1 className='font-sans font-medium'>Wednesday:</h1>
                                        <div><span className='text-green-600'>₹ {selRoomType.ratec}</span></div>
                                    </li>
                                    <li className='flex items-center justify-between'>
                                        <h1 className='font-sans font-medium'>Thursday:</h1>
                                        <div><span className='text-green-600'>₹ {selRoomType.rated}</span></div>
                                    </li>
                                    <li className='flex items-center justify-between'>
                                        <h1 className='font-sans font-medium'>Friday:</h1>
                                        <div><span className='text-green-600'>₹ {selRoomType.ratee}</span></div>
                                    </li>
                                    <li className='flex items-center justify-between'>
                                        <h1 className='font-sans font-medium'>Saturday:</h1>
                                        <div><span className='text-green-600'>₹ {selRoomType.ratef}</span></div>
                                    </li>
                                    <li className='flex items-center justify-between'>
                                        <h1 className='font-sans font-medium'>Sunday:</h1>
                                        <div><span className='text-green-600'>₹ {selRoomType.rateg}</span></div>
                                    </li>
                                </ul>
                            </div>
                        </ScrollArea >
                    </div >
                </div >
            }
            <ScrollArea className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                            <h2 class="text-2xl font-medium truncate ml-2">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Rooms Type
                            </h2>
                            <TopBar />
                        </div>
                        <div class="w-full mt-2">
                            <div className="p-2 mt-2">
                                <div className='w-full min-h-screen bg-gray-100 rounded-md grid grid-cols-12 gap-2 p-2'>
                                    <div className='col-span-2 h-full border-r p-2'>
                                        <button className="w-full h-10 py-1 px-3 rounded-md bg-prime hover:bg-primeLight text-white text-sm" onClick={() => { setIsOpen(true); }}><i class="las la-plus mr-1"></i> ADD ROOM TYPE</button>
                                    </div>
                                    <div className='col-span-10'>
                                        <div className='w-full my-4 border border-gray-200 border-dashed p-1 rounded'>
                                            <div className='w-full'>
                                                <div className='flex items-center justify-between'>
                                                    <div className='flex items-center'>
                                                        <Building size={22} />
                                                        <h1 className='text-xl font-medium uppercase ml-1'>Room Types</h1>
                                                    </div>
                                                </div>
                                                <div className='my-2 h-[1px] bg-gray-300'></div>
                                            </div>
                                            <div className='w-full grid grid-cols-6 gap-2'>
                                                {
                                                    types.length === 0 && < div className='h-[114px] p-2 rounded shadow col-span-6 border border-dashed border-prime cursor-pointer hover:shadow-md flex items-center justify-center'>
                                                        <h1 className='text-sm font-medium text-prime'>No data found.</h1>
                                                    </div>
                                                }
                                                {
                                                    types.length === 0 !== 0 && types.map((type) => {
                                                        return <div className="h-[114px] p-2 rounded shadow col-span-2 border border-dashed border-gray-400 cursor-pointer hover:shadow-md" key={type._id} onClick={() => {
                                                            setSelRoomType(type);
                                                            setIsOpenB(true);
                                                        }}>
                                                            <div className='flex justify-between items-center px-1'>
                                                                <div>
                                                                    <p className='text-xl font-medium font-serif'>{type.title}</p>
                                                                    <div className='flex items-center'>
                                                                        <CalendarClock size={18} />
                                                                    </div>
                                                                </div>
                                                                <img src={isRoom} alt='Table icon' className='w-[54px] h-[54px]' />
                                                            </div>
                                                            <div className='bg-gray-200 h-[1px] my-2'></div>
                                                            <div className='flex items-center justify-between'>
                                                                <div className='text-gray-600 flex items-center'>
                                                                    <HandPlatter size={20} />
                                                                    <p className='text-base font-medium font-serif ml-1'>₹ {type.ratea.toFixed(2)}</p>
                                                                </div>
                                                                <Trash2 size={18} className='cursor-pointer' onClick={() => {
                                                                    deleteRoom(type);
                                                                }} />
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollArea>
        </>
    )
}
